const consts = require('./consts');

const RedirectIndex = () => import('../components/modules/redirect/index');
const MainPage = () => import('../components/modules/main/main.page');
const PlatformMainPage = () => import('../components/modules/main/PlatformMainPage');
const ServicesMainPage = () =>
  import(/* webpackChunkName: "services-pages" */ '../components/modules/main/ServicesMainPage');
const PageNotFound = () => import('../components/page.not.found');
const PaymentCheckoutSuccessPage = () =>
  import('../components/modules/paymentCheckoutSuccess/PaymentCheckoutSuccessPage');
const RedirectResolver = () =>
  import(/* webpackChunkName: "redirect-resolver-page" */ '@common/pages/RedirectResolver');

const Login = () => import(/* webpackChunkName: "basic-pages" */ '../components/modules/login/login');
const Register = () => import(/* webpackChunkName: "basic-pages" */ '../components/modules/login/register');
const GetStartedPage = () =>
  import(/* webpackChunkName: "basic-pages" */ '../components/modules/getStarted/GetStartedPage');
const UserProfile = () => import(/* webpackChunkName: "basic-pages" */ '../components/modules/user/profile');
const VerifyAccountPage = () =>
  import(/* webpackChunkName: "basic-pages" */ '../components/modules/verify/VerifyAccountPage');
const RequireVerifyAccountPage = () =>
  import(/* webpackChunkName: "basic-pages" */ '../components/modules/verify/RequireVerifyAccountPage');
const ForgotPwd = () => import(/* webpackChunkName: "basic-pages" */ '../components/modules/login/forgotpwd');
const ForgotEmailSent = () =>
  import(/* webpackChunkName: "basic-pages" */ '../components/modules/login/forgot.emailsent');
const ResetPwd = () => import(/* webpackChunkName: "basic-pages" */ '../components/modules/login/reset.password');

const CommunityPage = () =>
  import(/* webpackChunkName: "community-pages" */ '../components/modules/aboutUs/community/CommunityPage');

const WikiHomePage = () =>
  import(/* webpackChunkName: "wiki-pages" */ '../components/modules/wiki/article/WikiHomePage');
const WikiArticlePage = () =>
  import(/* webpackChunkName: "wiki-pages" */ '../components/modules/wiki/article/WikiArticlePage');
const WikiCategoryPage = () =>
  import(/* webpackChunkName: "wiki-pages" */ '../components/modules/wiki/article/WikiCategoryPage');
const WikiSearchPage = () =>
  import(/* webpackChunkName: "wiki-pages" */ '../components/modules/wiki/search/WikiSearchPage');
const WikiEventsPage = () => import(/* webpackChunkName: "wiki-pages" */ '@common/pages/wiki/WikiEventsPage');

const ZupuDetailsPage = () =>
  import(/* webpackChunkName: "zupu-search-pages" */ '../components/modules/zupuDetails/ZupuDetailsPage');
const myZupusPageContainer = () =>
  import(/* webpackChunkName: "zupu-search-pages" */ '../components/modules/myZupus/myZupusPageContainer');

const MyOrdersPage = () => import(/* webpackChunkName: "order-pages" */ '../components/modules/myOrders/MyOrdersPage');

const SourceViewerPage = () =>
  import(/* webpackChunkName: "book-pages" */ '../components/modules/sourceViewer/SourceViewerPage');
const SourceDetailsPage = () =>
  import(/* webpackChunkName: "book-pages" */ '../components/modules/sourceDetails/SourceDetailsPage');
const CollectionDetailsPage = () =>
  import(/* webpackChunkName: "book-pages" */ '../components/modules/sourceCollectionDetails/CollectionDetailsPage');

const CemeteryDetailsPage = () =>
  import(/* webpackChunkName: "cemetery-pages" */ '@common/pages/cemetery/CemeteryDetailsPage');

const SearchVillagesPage = () =>
  import(/* webpackChunkName: "village-search-pages" */ '../components/modules/searchVillages/SearchVillagesPage');
const VillageDetailsPage = () =>
  import(
    /* webpackChunkName: "village-search-pages" */ '../components/modules/placeDetails/villageDetails/VillageDetailsPage'
  );

const SearchOverseasPage = () =>
  import(/* webpackChunkName: "village-search-pages" */ '@common/pages/searchPlacesOverseas/SearchOverseasBasePage');
const MigrationDestinationDetailsPage = () =>
  import(
    /* webpackChunkName: "migration-place-pages" */ '../components/modules/placeDetails/migrationDestinationDetails/MigrationDestinationDetailsPage'
  );
const PlaceDetailsPage = () =>
  import(/* webpackChunkName: "village-search-pages" */ '../components/modules/placeDetails/PlaceDetailsPage');

const SearchSurnamesPage = () =>
  import(/* webpackChunkName: "surname-search-page" */ '@common/pages/searchSurnames/SearchSurnamesPage');
const SurnameDetailsPageContainer = () =>
  import(
    /* webpackChunkName: "surname-details-pages" */ '../components/modules/surnameDetails/SurnameDetailsPageContainer'
  );
const SurnameDetailsPage = () =>
  import(/* webpackChunkName: "surname-details-pages" */ '../components/modules/surnameDetails/SurnameDetailsPage');
const SurnameVariantDetailsPage = () =>
  import(
    /* webpackChunkName: "surname-details-pages" */ '../components/modules/surnameVariantDetails/SurnameVariantDetailsPage'
  );
const DialectPage = () =>
  import(/* webpackChunkName: "surname-details-pages" */ '../components/modules/dialects/DialectPage');
const SearchJoinOfferPage = () =>
  import(/* webpackChunkName: "surname-details-pages" */ '../components/modules/paywalls/SearchJoinOfferPage');

const AdoptionPage = () =>
  import(/* webpackChunkName: "services-pages" */ '../components/modules/services/adoption/adoption.page');
const ProbatePage = () =>
  import(/* webpackChunkName: "services-pages" */ '../components/modules/services/probate/probate.page');
const TranslationPage = () =>
  import(/* webpackChunkName: "services-pages" */ '../components/modules/services/translation/translation.page');
const WritingPage = () =>
  import(/* webpackChunkName: "services-pages" */ '../components/modules/services/writing/writing.page');
const FilmPage = () => import(/* webpackChunkName: "services-pages" */ '../components/modules/services/film/film.page');
const TravelPage = () =>
  import(/* webpackChunkName: "services-pages" */ '../components/modules/services/travel/travel.page');
const NameTranslationPage = () =>
  import(
    /* webpackChunkName: "services-pages" */ '../components/modules/services/nametranslation/name.translation.page'
  );
const VillageLocationPage = () =>
  import(/* webpackChunkName: "services-pages" */ '../components/modules/services/village/village.location.page');
const VillageDiscoverPage = () =>
  import(/* webpackChunkName: "services-pages" */ '../components/modules/services/village/village.exploration.page');
const ZupuTranslationMainPage = () =>
  import(
    /* webpackChunkName: "zupu-services-pages" */ '../components/modules/services/zupuTranslation/ZupuTranslationMainPage'
  );
const ZupuTableOfContentsPage = () =>
  import(
    /* webpackChunkName: "zupu-services-pages" */ '../components/modules/services/zupuTranslation/ZupuTableOfContentsPage'
  );
const ZupuAncestorNamePage = () =>
  import(
    /* webpackChunkName: "zupu-services-pages" */ '../components/modules/services/zupuTranslation/ZupuAncestorNamePage'
  );
const ZupuGenerationPoemPage = () =>
  import(
    /* webpackChunkName: "zupu-services-pages" */ '../components/modules/services/zupuTranslation/ZupuGenerationPoemPage'
  );
const ZupuAncestralLinePage = () =>
  import(
    /* webpackChunkName: "zupu-services-pages" */ '../components/modules/services/zupuTranslation/ZupuAncestralLinePage'
  );
const ZupuTreeTranslatePage = () =>
  import(
    /* webpackChunkName: "zupu-services-pages" */ '../components/modules/services/zupuTranslation/ZupuTreeTranslatePage'
  );

const InstitutionsIndexingPage = () =>
  import(/* webpackChunkName: "services-pages" */ '@common/pages/institutions/InstitutionsIndexingPage');

const TestimonialsPage = () =>
  import(/* webpackChunkName: "about-pages" */ '../components/modules/testimonials/testimonials.page');
const CareersPage = () =>
  import(/* webpackChunkName: "about-pages" */ '../components/modules/aboutUs/careers/careers.page');
const ContactPage = () =>
  import(/* webpackChunkName: "about-pages" */ '../components/modules/aboutUs/contact/contact.page');
const PressPage = () => import(/* webpackChunkName: "about-pages" */ '../components/modules/aboutUs/press/press.page');
const AboutPage = () => import(/* webpackChunkName: "about-pages" */ '../components/modules/aboutUs/about/about.page');
const OurTeamPage = () => import(/* webpackChunkName: "about-pages" */ '../components/modules/aboutUs/team/team.page');

const FamilyTreePageContainer = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTree/familytree.page.container');
const FamilyTreePage = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTree/familytree.page');
const FamilyTreeLandingPage = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTree/familytree.landing.page');
const FamilyTreeProfilePage = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeProfile/FamilyTreeProfilePage');
const FamilyTreeClanPage = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeClans/FamilyTreeClanPage');
const FamilyTreeClansPage = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeClans/FamilyTreeClansPage');
const FamilyTreeLineagePage = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '@/components/modules/familyTreeLineage/FamilyTreeLineagePage');
const CreateTreePage = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeManage/CreateTreePage');
const ImportGedcomPage = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeManage/ImportGedcomPage');
const FamilyTreeOnboardingPage = () =>
  import(
    /* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeOnboarding/FamilyTreeOnboardingPage'
  );
const OnboardingStart = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeOnboarding/OnboardingStart');
const OnboardingMe = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeOnboarding/OnboardingMe');
const OnboardingMother = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeOnboarding/OnboardingMother');
const OnboardingFather = () =>
  import(/* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeOnboarding/OnboardingFather');
const OnboardingGrandparent = () =>
  import(
    /* webpackChunkName: "family-tree-pages" */ '../components/modules/familyTreeOnboarding/OnboardingGrandparent'
  );
const HintsLandingPage = () =>
  import(/* webpackChunkName: "hint-landing-pages" */ '@/components/modules/familyTreeHints/HintsLandingPage');
const HintsPage = () => import(/* webpackChunkName: "hint-pages" */ '@/components/modules/familyTreeHints/HintsPage');
const HintReviewPage = () =>
  import(/* webpackChunkName: "hint-pages" */ '@/components/modules/familyTreeHints/HintReviewPage');
const ManageAllTreesPage = () =>
  import(/* webpackChunkName: "family-tree-manage" */ '../components/modules/familyTreeManage/ManageAllTreesPage');
const ManageTreePage = () =>
  import(/* webpackChunkName: "family-tree-manage" */ '../components/modules/familyTreeManage/ManageTreePage');
const ManageTreeMobileMenu = () =>
  import(
    /* webpackChunkName: "family-tree-manage" */ '../components/modules/familyTreeManage/manageTree/ManageTreeMobileMenu'
  );
const ManageTreeDetailsPage = () =>
  import(
    /* webpackChunkName: "family-tree-manage" */ '../components/modules/familyTreeManage/manageTree/ManageTreeDetailsPage'
  );
const ManageTreePrivacyPage = () =>
  import(
    /* webpackChunkName: "family-tree-manage" */ '../components/modules/familyTreeManage/manageTree/ManageTreePrivacyPage'
  );
const ManageTreeSharingPage = () =>
  import(
    /* webpackChunkName: "family-tree-manage" */ '../components/modules/familyTreeManage/manageTree/ManageTreeSharingPage'
  );
const ManageTreeExportPage = () =>
  import(
    /* webpackChunkName: "family-tree-manage" */ '../components/modules/familyTreeManage/manageTree/ManageTreeExportPage'
  );
const ManageTreeCustomizePage = () =>
  import(
    /* webpackChunkName: "family-tree-manage" */ '../components/modules/familyTreeManage/manageTree/ManageTreeCustomizePage'
  );

const PrivacyPolicyPage = () =>
  import(/* webpackChunkName: "terms-pages" */ '../components/modules/privacyPolicy/PrivacyPolicyPage');
const TermsAndConditionsPage = () =>
  import(/* webpackChunkName: "terms-pages" */ '../components/modules/terms/TermsAndConditionsPage');

const SupportPage = () => import(/* webpackChunkName: "support-pages" */ '../components/modules/support/SupportPage');

const FamilyTreeLibraryPage = () =>
  import(/* webpackChunkName: "family-tree-library" */ '../components/modules/familyTreeLibrary/FamilyTreeLibraryPage');

const LibraryUploadPage = () =>
  import(/* webpackChunkName: "family-tree-library" */ '../components/modules/familyTreeLibrary/LibraryUploadPage');

const FamilyTreeLibraryLandingPage = () =>
  import(
    /* webpackChunkName: "family-tree-library" */ '../components/modules/familyTreeLibrary/FamilyTreeLibraryLandingPage'
  );
const FamilyTreeLibraryAssetDetailsRouting = () =>
  import(
    /* webpackChunkName: "family-tree-library" */ '../components/modules/familyTreeLibrary/FamilyTreeLibraryAssetDetailsRouting'
  );
const FamilyWebsitePage = () =>
  import(/* webpackChunkName: "family-website" */ '../components/modules/familyWebsite/FamilyWebsitePage');
const FamilyWebsitePageContainer = () =>
  import(/* webpackChunkName: "family-website" */ '../components/modules/familyWebsite/FamilyWebsitePageContainer');

const PersonRecordsPage = () =>
  import(/* webpackChunkName: "records-pages" */ '../components/modules/personRecords/PersonRecordsPage');
const SearchAllRecordsUnifiedPage = () =>
  import(
    /* webpackChunkName: "records-pages" */ '../components/modules/searchAllRecordsUnified/SearchAllRecordsUnifiedPage'
  );
const MyRecordInboxPage = () =>
  import(/* webpackChunkName: "records-pages" */ '../components/modules/myRecordInbox/MyRecordInboxPage');

const ContactUsPage = () =>
  import(/* webpackChunkName: "contact-us-page" */ '../components/modules/contactUs/ContactUsPage');
const ContactUsSuccessPage = () =>
  import(/* webpackChunkName: "contact-us-page" */ '../components/modules/getStarted/ContactUsSuccessPage');

const subscriptionAlreadyActivePage = () =>
  import(
    /* webpackChunkName: "subscription-confirm" */ '../components/modules/user/email-settings/subscriptionAlreadyActivePage'
  );
const subscriptionConfirmPage = () =>
  import(
    /* webpackChunkName: "subscription-confirm" */ '../components/modules/user/email-settings/subscriptionConfirmPage'
  );
const subscriptionSuccessfulPage = () =>
  import(
    /* webpackChunkName: "subscription-confirm" */ '../components/modules/user/email-settings/subscriptionSuccessfulPage'
  );

const SubscriptionPlansPage = () =>
  import(/* webpackChunkName: "subscription-plans" */ '../components/modules/subscriptionPlans/SubscriptionPlansPage');
const SubscriptionCanceledPage = () =>
  import(
    /* webpackChunkName: "subscription-plans" */ '../components/modules/subscriptionPlans/SubscriptionCanceledPage'
  );
const ShareLinkPage = () =>
  import(/* webpackChunkName: "subscription-plans" */ '../components/modules/subscriptionPlans/ShareLinkPage');

const routes = [
  {
    // Main page of the website
    path: '/',
    name: 'main-page',
    component: MainPage,
    meta: {hideMainMenu: true, showMcrFooter: true, includeToSitemap: true, prerender: true},
  },
  {
    path: '/testimonials',
    name: 'testimonials',
    component: TestimonialsPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/services',
    name: 'services-main',
    component: ServicesMainPage,
    meta: {
      hideMainMenu: true,
      showMcrFooter: true,
      includeToSitemap: true,
      displayGlossary: true,
      prerender: true,
      isService: true,
    },
  },
  {
    path: '/search',
    name: 'platform-main',
    component: PlatformMainPage,
    meta: {hideMainMenu: true, showMcrFooter: true, includeToSitemap: true, displayGlossary: true, prerender: true},
  },
  {
    path: '/services/research/ancestry',
    name: 'ancestry',
    redirect: {name: 'services-main'},
  },
  {
    path: '/services/research/adoption',
    name: 'adoption',
    component: AdoptionPage,
    meta: {showMcrFooter: true, includeToSitemap: true, displayGlossary: true, isService: true},
  },
  {
    path: '/services/research/probate',
    name: 'probate',
    component: ProbatePage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/services/translation',
    name: 'translation',
    component: TranslationPage,
    meta: {showMcrFooter: true, includeToSitemap: true, prerender: true, isService: true},
  },
  {
    path: '/services/storytelling',
    name: 'storytelling',
    component: WritingPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/services/writing',
    redirect: {name: 'storytelling'},
  },
  {
    path: '/services/film',
    name: 'film',
    component: FilmPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/services/travel',
    name: 'travel',
    component: TravelPage,
    meta: {showMcrFooter: true, includeToSitemap: true, prerender: true, isService: true},
  },
  {
    path: '/services/research/name-translation',
    name: 'name-translation',
    component: NameTranslationPage,
    meta: {showMcrFooter: true, includeToSitemap: true, prerender: true, isService: true},
  },
  {
    path: '/services/research/village-exploration',
    name: 'village-exploration-service',
    component: VillageDiscoverPage,
    meta: {showMcrFooter: true, includeToSitemap: true, prerender: true, displayGlossary: true, isService: true},
  },
  {
    path: '/services/research/village-location',
    name: 'village-location-service',
    component: VillageLocationPage,
    meta: {showMcrFooter: true, includeToSitemap: true, prerender: true, displayGlossary: true, isService: true},
  },
  {
    path: '/services/zupu',
    name: 'zupu-translation-services',
    component: ZupuTranslationMainPage,
    meta: {showMcrFooter: true, includeToSitemap: true, prerender: true, isService: true},
  },
  {
    path: '/services/zupu/table-of-contents',
    name: 'zupu-table-of-contents',
    component: ZupuTableOfContentsPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/services/zupu/ancestor-name',
    name: 'zupu-ancestor-name',
    component: ZupuAncestorNamePage,
    meta: {showMcrFooter: true, includeToSitemap: true, displayGlossary: true, isService: true},
  },
  {
    path: '/services/zupu/generation-poem',
    name: 'zupu-generation-poem',
    component: ZupuGenerationPoemPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/services/zupu/ancestral-lineage',
    name: 'zupu-ancestral-lineage',
    component: ZupuAncestralLinePage,
    meta: {showMcrFooter: true, includeToSitemap: true, displayGlossary: true, isService: true},
  },
  {
    path: '/services/zupu/transcribe',
    name: 'zupu-transcribe',
    component: ZupuTreeTranslatePage,
    meta: {showMcrFooter: true, includeToSitemap: true, displayGlossary: true, isService: true},
  },
  {
    path: '/services/institutions/indexing',
    name: 'institutions-indexing',
    component: InstitutionsIndexingPage,
    meta: {showMcrFooter: true, includeToSitemap: true, displayGlossary: true, isService: true},
  },
  {
    path: '/subscription-plans',
    name: 'subscription-plans',
    component: SubscriptionPlansPage,
    meta: {showMcrFooter: true, includeToSitemap: true, displayGlossary: true, hideBannerSubscription: true},
  },
  {
    path: '/subscription-canceled',
    name: 'subscription-canceled',
    component: SubscriptionCanceledPage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/share-link/:code',
    name: 'share-link',
    component: ShareLinkPage,
    meta: {hideBannerSubscription: true},
  },
  {
    path: '/careers',
    name: 'careers',
    component: CareersPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/press',
    name: 'press',
    component: PressPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/our-story',
    name: 'our-story',
    component: AboutPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/team',
    name: 'our-team',
    component: OurTeamPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true},
  },
  {
    path: '/community',
    name: 'community',
    component: CommunityPage,
    meta: {showMcrFooter: true, includeToSitemap: true, isService: true, prerender: true},
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {hideMainMenu: true},
  },
  {
    path: '/forgotpwd',
    name: 'forgotpwd',
    component: ForgotPwd,
    meta: {},
  },
  {
    path: '/forgotpwd/emailsent/:email',
    name: 'forgot-email-sent',
    component: ForgotEmailSent,
    meta: {},
  },
  {
    path: '/reset-password',
    name: 'reset-pwd',
    component: ResetPwd,
    meta: {},
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {hideMainMenu: true},
  },
  {
    path: '/register/:base64Text',
    name: 'register-pr',
    component: Register,
    meta: {},
  },
  {
    path: '/events',
    name: 'events',
    component: WikiEventsPage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/wiki',
    name: 'wiki-index',
    component: WikiHomePage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/wiki/article/:slug',
    name: 'wiki-article',
    component: WikiArticlePage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.slug},
  },
  {
    path: '/wiki/search',
    name: 'wiki-search',
    component: WikiSearchPage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/wiki/category/:slug',
    name: 'wiki-category',
    component: WikiCategoryPage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.slug},
  },
  {
    path: '/wiki/category',
    redirect: to => ({name: 'wiki-category', params: {slug: to.query.categoryId || to.query.object_id}, query: {}}),
  },
  {
    path: '/profile',
    name: 'user-profile',
    component: UserProfile,
    meta: {authRequired: true},
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: VerifyAccountPage,
    meta: {},
  },
  {
    path: '/require-verify',
    name: 'require-verify',
    component: RequireVerifyAccountPage,
    meta: {authRequired: true},
  },
  {
    path: '/get-in-touch',
    name: 'get-in-touch',
    component: ContactUsPage,
    meta: {isService: true},
  },
  {
    path: '/get-in-touch/success',
    name: 'get-in-touch-success',
    component: ContactUsSuccessPage,
    meta: {showMcrFooter: true, isService: true},
  },
  {
    path: '/pr',
    redirect: {name: 'get-in-touch'},
  },
  {
    path: '/pr/interests',
    redirect: {name: 'get-in-touch'},
  },
  {
    path: '/pr/:step',
    redirect: {name: 'get-in-touch'},
  },
  {
    path: '/searchTool/zupu',
    redirect: {name: 'search-tool-zupu'},
  },
  {
    path: '/search/zupus',
    name: 'search-tool-zupu',
    redirect: to => ({name: 'search-all-records', query: {tab: 'full-text', ...to.query, category_id: 'zupus'}}),
  },
  {
    path: '/searchTool/zupu/detail/:zupuId',
    redirect: to => ({name: 'search-tool-zupu-detail', params: {zupuId: to.params.zupuId}}),
  },
  {
    path: '/zupus/:zupuId',
    name: 'search-tool-zupu-detail',
    component: ZupuDetailsPage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.zupuId},
  },
  {
    path: '/zupus/:zupuId/viewer',
    name: 'zupu-viewer',
    component: RedirectResolver,
    meta: {getRouteName: route => '', hideMainMenu: true, customPageOpenTrack: true},
  },
  {
    path: '/source/:sourceId',
    name: 'source-detail',
    component: SourceDetailsPage,
    meta: {getIdentifier: route => route.params.sourceId, showMcrFooter: true},
  },
  {
    path: '/source/:sourceId/viewer',
    name: 'source-viewer',
    component: SourceViewerPage,
    meta: {getIdentifier: route => route.params.sourceId, hideMainMenu: true, customPageOpenTrack: true},
  },
  {
    path: '/source-collections/:id',
    name: 'collection-detail',
    component: CollectionDetailsPage,
    meta: {getIdentifier: route => route.params.id, showMcrFooter: true},
  },
  {
    path: '/searchTool/village',
    redirect: {name: 'search-tool-village'},
  },
  {
    path: '/search/villages',
    name: 'search-tool-village',
    component: SearchVillagesPage,
    meta: {showMcrFooter: true, includeToSitemap: true, displayGlossary: true},
  },
  {
    path: '/search/overseas',
    name: 'search-overseas',
    component: SearchOverseasPage,
    meta: {showMcrFooter: true, includeToSitemap: true, displayGlossary: true},
  },
  {
    path: '/places/:placeId/:slug?',
    name: 'place-detail',
    component: PlaceDetailsPage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.placeId},
  },
  {
    path: '/searchTool/village/detail/:villageId',
    redirect: to => ({name: 'place-detail', params: {placeId: to.params.villageId}}),
  },
  {
    path: '/villages/:villageId/:slug?',
    name: 'search-tool-village-detail',
    redirect: to => ({name: 'place-detail', params: {placeId: to.params.villageId, slug: to.params.slug}}),
  },
  {
    path: '/migration-destination/:placeId/:slug?',
    name: 'migration-destination-detail',
    redirect: to => ({name: 'place-detail', params: {placeId: to.params.placeId, slug: to.params.slug}}),
  },
  {
    path: '/search/records',
    name: 'search-all-records',
    component: SearchAllRecordsUnifiedPage,
    meta: {
      showMcrFooter: true,
      displayGlossary: true,
      includeToSitemap: true,
      rememberScrollPosition: true,
      getRouteName: route => (route.query.category_id ? 'search-category-records' : 'search-all-records'),
      getIdentifier: route => route.query.category_id || '',
    },
  },
  {
    path: '/search/burial-records',
    name: 'search-burial-records',
    redirect: to => ({
      name: 'search-all-records',
      query: {tab: 'indexed-records', ...to.query, category_id: 'birth-marriage-death'},
    }),
  },
  {
    path: '/search/books-records',
    name: 'search-books-records',
    redirect: to => ({
      name: 'search-all-records',
      query: {tab: 'indexed-records', ...to.query, category_id: 'associations-directories'},
    }),
  },
  {
    path: '/search/immigration-records',
    name: 'search-immigration-records',
    redirect: to => ({
      name: 'search-all-records',
      query: {tab: 'indexed-records', ...to.query, category_id: 'immigration'},
    }),
  },
  {
    path: '/search/sources',
    name: 'search-sources',
    redirect: to => ({name: 'search-all-records', query: {tab: 'sources', ...to.query, results: 'true'}}),
  },
  {
    path: '/cemeteries/:id/:slug?',
    name: 'cemetery-detail',
    component: CemeteryDetailsPage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.id},
  },
  {
    path: '/person-records/:code/:slug?',
    name: 'person-records-detail',
    component: PersonRecordsPage,
    meta: {
      showMcrFooter: true,
      getIdentifier: route => route.params.code,
      authRequired: false,
      verifyRequired: false,
      displayGlossary: true,
    },
  },
  {
    path: '/searchTool/surname',
    redirect: {name: 'search-tool-surname'},
  },
  {
    path: '/search/surnames',
    name: 'search-tool-surname',
    component: SearchSurnamesPage,
    meta: {showMcrFooter: true, includeToSitemap: true, displayGlossary: true},
  },
  {
    path: '/searchTool/surname/detail',
    redirect: {name: 'search-tool-surname-detail'},
  },
  {
    path: '/surnames/detail',
    name: 'search-tool-surname-detail',
    component: SurnameDetailsPageContainer,
    meta: {
      displayGlossary: true,
      showMcrFooter: true,
      getIdentifier: route => route.query.word,
      getRouteName: route => {
        const isChineseText = route.query.word && route.query.word.match(/[\u3400-\u9FBF]/gi);
        return isChineseText ? route.name : 'surname-variant-detail';
      },
    },
  },
  {
    path: '/dialects/:slug',
    name: 'dialect-detail',
    component: DialectPage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/search-join-offer',
    name: 'search-join-offer',
    component: SearchJoinOfferPage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/prerender/clan/',
    name: 'prerender-clan',
    component: SurnameDetailsPage,
    meta: {prerender: true, isPrerenderTemplate: true},
  },
  {
    path: '/prerender/clan-variant/',
    name: 'prerender-clan-variant',
    component: SurnameVariantDetailsPage,
    meta: {prerender: true, isPrerenderTemplate: true},
  },
  {
    path: '/prerender/village/',
    name: 'prerender-village',
    component: VillageDetailsPage,
    meta: {prerender: true, isPrerenderTemplate: true},
  },
  {
    path: '/prerender/migration-destination/',
    name: 'prerender-migration-destination',
    component: MigrationDestinationDetailsPage,
    meta: {prerender: true, isPrerenderTemplate: true},
  },
  {
    path: '/prerender/source/',
    name: 'prerender-source',
    component: SourceDetailsPage,
    meta: {prerender: true, isPrerenderTemplate: true},
  },
  {
    path: '/prerender/zupu/',
    name: 'prerender-zupu',
    component: ZupuDetailsPage,
    meta: {prerender: true, isPrerenderTemplate: true},
  },
  {
    path: '/prerender/cemetery/',
    name: 'prerender-cemetery',
    component: CemeteryDetailsPage,
    meta: {prerender: true, isPrerenderTemplate: true},
  },
  {
    path: '/get-started',
    name: 'get-started',
    component: GetStartedPage,
    meta: {showMcrFooter: true, authRequired: true},
  },
  {
    path: '/searchTool/worldcatalog',
    redirect: {name: 'search-sources'},
  },
  {
    path: '/search/worldcatalog',
    redirect: 'search-sources',
  },
  {
    path: '/searchTool/worldcatalog/results',
    redirect: {name: 'search-sources'},
  },
  {
    path: '/search/worldcatalog/results',
    redirect: 'search-sources',
  },
  {
    path: '/searchTool/worldcatalog/detail/:zupuCatagoryId',
    redirect: {name: 'search-sources'},
  },
  {
    path: '/search/worldcatalog/detail/:zupuCatagoryId',
    redirect: 'search-sources',
  },
  {
    path: '/my-orders',
    name: 'my-orders',
    component: MyOrdersPage,
    meta: {displayGlossary: true},
  },
  {
    path: '/my-zupus',
    name: 'my-zupus',
    component: myZupusPageContainer,
    meta: {displayGlossary: true},
  },
  {
    path: '/my-records',
    redirect: {name: 'record-inbox'},
  },
  {
    path: '/record-inbox',
    name: 'record-inbox',
    component: MyRecordInboxPage,
    meta: {displayGlossary: true, authRequired: true},
  },
  {
    path: '/myTraces/welcome',
    redirect: {name: consts.MY_FT_LIBRARY_ROUTE_NAME},
  },
  {
    path: '/myTraces/profile',
    redirect: {name: consts.MY_FT_LIBRARY_ROUTE_NAME},
  },
  {
    path: '/myTraces/detail/:detailId',
    redirect: {name: consts.MY_FT_LIBRARY_ROUTE_NAME},
  },
  {
    path: '/myTraces/dashboard',
    redirect: {name: consts.MY_FT_LIBRARY_ROUTE_NAME},
  },
  {
    path: '/report/raymonddouglaschong',
    component: RedirectIndex,
  },
  {
    path: '/report/raymonddouglaschong/ancestors',
    component: RedirectIndex,
  },
  {
    path: '/report/:username',
    name: 'jlt-report-index',
    component: FamilyWebsitePageContainer,
    meta: {hideMainMenu: true, displayGlossary: true, isJLTReport: true},
  },
  {
    path: '/report/:username/:sitename',
    name: 'jlt-report-site-name',
    component: FamilyWebsitePageContainer,
    meta: {hideMainMenu: true, displayGlossary: true, isJLTReport: true},
  },
  {
    path: '/report/:username/:sitename/:partkey',
    name: 'jlt-report-site-name-part',
    component: FamilyWebsitePageContainer,
    meta: {
      hideMainMenu: true,
      displayGlossary: true,
      isJLTReport: true,
      getIdentifier: route => `${route.params.sitename}-${route.params.partkey}`,
    },
  },
  {
    path: '/fws/:username',
    name: 'fws',
    component: FamilyWebsitePage,
    meta: {hideMainMenu: true, displayGlossary: true, isFWSReport: true},
  },
  {
    path: '/fws/:username/:sitename',
    name: 'fws-site-name',
    component: FamilyWebsitePage,
    meta: {hideMainMenu: true, displayGlossary: true, isFWSReport: true},
  },
  {
    path: '/fws/:username/:sitename/:partkey',
    name: 'fws-site-name-part',
    component: FamilyWebsitePage,
    meta: {hideMainMenu: true, displayGlossary: true, isFWSReport: true},
  },
  {
    path: '/familytree-landing',
    redirect: {name: 'familytree-details-my'},
    meta: {},
  },
  {
    path: '/familytree',
    name: 'familytree-details-my',
    component: FamilyTreeLandingPage,
  },
  {
    path: '/familytree/lineage',
    name: 'familytree-lineage-my',
    component: FamilyTreeLandingPage,
    meta: {},
  },
  {
    path: '/familytree/manage',
    name: 'familytree-manage-all',
    component: ManageAllTreesPage,
    meta: {authRequired: true, forceOnboarding: true},
  },
  {
    path: '/familytree/create',
    name: 'familytree-create',
    component: CreateTreePage,
    meta: {authRequired: true, forceOnboarding: true},
  },
  {
    path: '/familytree/import/gedcom',
    name: 'familytree-import-gedcom',
    component: ImportGedcomPage,
    meta: {authRequired: true},
  },
  {
    path: '/familytree/hints-landing',
    name: 'familytree-hints-landing',
    component: HintsLandingPage,
    meta: {showMcrFooter: true, forceOnboarding: true},
  },
  {
    path: '/familytree/onboarding',
    name: 'familytree-onboarding',
    component: FamilyTreeOnboardingPage,
    meta: {hideMainMenu: true, authRequired: true},
    children: [
      {
        path: 'start',
        name: 'familytree-onboarding-start',
        component: OnboardingStart,
        meta: {hideMainMenu: true, authRequired: true},
      },
      {
        path: 'me',
        name: 'familytree-onboarding-me',
        component: OnboardingMe,
        meta: {hideMainMenu: true, isStep: true, authRequired: true},
      },
      {
        path: 'mother',
        name: 'familytree-onboarding-mother',
        component: OnboardingMother,
        meta: {hideMainMenu: true, isStep: true, authRequired: true},
      },
      {
        path: 'father',
        name: 'familytree-onboarding-father',
        component: OnboardingFather,
        meta: {hideMainMenu: true, isStep: true, authRequired: true},
      },
      {
        path: 'grandparent',
        name: 'familytree-onboarding-grandparent',
        component: OnboardingGrandparent,
        meta: {hideMainMenu: true, isStep: true, authRequired: true},
      },
    ],
  },
  {
    path: '/familytree/:id',
    component: FamilyTreePageContainer,
    children: [
      {
        path: '',
        component: FamilyTreePage,
        name: 'familytree-details',
        meta: {
          displayBrowserNotSupported: true,
          keepAlive: true,
          getIdentifier: route => route.params.id,
          displayControls: true,
          forceOnboarding: true,
          displayGlossary: true,
        },
      },
      {
        path: 'lineage',
        component: FamilyTreeLineagePage,
        name: 'familytree-lineage',
        meta: {
          displayBrowserNotSupported: true,
          keepAlive: true,
          getIdentifier: route => route.params.id,
          displayControls: true,
          forceOnboarding: true,
          displayGlossary: true,
        },
      },
      {
        path: 'profile/:personId',
        component: FamilyTreeProfilePage,
        name: 'familytree-profile-details',
        meta: {
          keepAlive: true,
          getIdentifier: route => route.params.personId,
          forceOnboarding: true,
          displayGlossary: true,
        },
      },
      {
        path: 'clans',
        component: FamilyTreeClansPage,
        name: 'familytree-clans',
        meta: {
          getIdentifier: route => route.params.id,
          forceOnboarding: true,
          displayGlossary: true,
        },
      },
      {
        path: 'clans/:name',
        component: FamilyTreeClanPage,
        name: 'familytree-clan-details',
        meta: {
          getIdentifier: route => route.params.name,
          forceOnboarding: true,
          displayGlossary: true,
        },
      },
    ],
  },
  {
    path: '/familytree/:id/hints',
    name: 'familytree-hints',
    component: HintsPage,
    meta: {authRequired: true, getIdentifier: route => route.params.id, forceOnboarding: true, displayGlossary: true},
  },
  {
    path: '/hints/review/:hintId',
    name: 'hint-review',
    component: HintReviewPage,
    meta: {authRequired: true, forceOnboarding: true, displayGlossary: true},
  },
  {
    path: '/familytree/:id/library',
    component: FamilyTreeLibraryPage,
    name: 'familytree-library',
    meta: {getIdentifier: route => route.params.id, forceOnboarding: true},
  },
  {
    path: '/familytree/:id/library/upload',
    component: LibraryUploadPage,
    name: consts.FT_LIBRARY_UPLOAD_ROUTE_NAME,
    meta: {getIdentifier: route => route.params.id, forceOnboarding: true, hideMainMenu: true, hideNotifications: true},
  },
  {
    path: '/library/file/:assetId',
    component: FamilyTreeLibraryAssetDetailsRouting,
    name: consts.FT_LIBRARY_ASSET_DETAILS_ROUTE_NAME,
    meta: {getIdentifier: route => route.params.assetId, forceOnboarding: true},
  },
  {
    path: '/library',
    component: FamilyTreeLibraryLandingPage,
    name: consts.MY_FT_LIBRARY_ROUTE_NAME,
  },
  {
    path: '/library-landing',
    redirect: {name: consts.MY_FT_LIBRARY_ROUTE_NAME},
  },
  {
    path: '/familytree/:id/manage',
    component: ManageTreePage,
    meta: {getIdentifier: route => route.params.id, authRequired: true, forceOnboarding: true},
    children: [
      {
        path: '',
        component: ManageTreeMobileMenu,
        name: 'familytree-manage',
        meta: {getIdentifier: route => route.params.id, authRequired: true, forceOnboarding: true},
      },
      {
        path: 'details',
        component: ManageTreeDetailsPage,
        name: 'familytree-manage-details',
        meta: {getIdentifier: route => route.params.id, authRequired: true, forceOnboarding: true},
      },
      {
        path: 'privacy',
        component: ManageTreePrivacyPage,
        name: 'familytree-manage-privacy',
        meta: {getIdentifier: route => route.params.id, authRequired: true, forceOnboarding: true},
      },
      {
        path: 'sharing',
        component: ManageTreeSharingPage,
        name: 'familytree-manage-sharing',
        meta: {getIdentifier: route => route.params.id, authRequired: true, forceOnboarding: true},
      },
      {
        path: 'export',
        component: ManageTreeExportPage,
        name: 'familytree-manage-export',
        meta: {getIdentifier: route => route.params.id, authRequired: true, forceOnboarding: true},
      },
      {
        path: 'customize',
        component: ManageTreeCustomizePage,
        name: 'familytree-manage-customize',
        meta: {getIdentifier: route => route.params.id, authRequired: true, forceOnboarding: true},
      },
    ],
  },
  {
    path: '/privacy',
    component: PrivacyPolicyPage,
    name: 'privacy-policy',
    meta: {showMcrFooter: true, isService: true},
  },
  {
    path: '/terms',
    component: TermsAndConditionsPage,
    name: 'terms',
    meta: {showMcrFooter: true, isService: true},
  },
  {
    path: '/support',
    name: 'support',
    component: SupportPage,
    meta: {authRequired: true},
  },
  {
    path: '/subscription-active',
    component: subscriptionAlreadyActivePage,
    name: 'subscription-already-active',
    meta: {showMcrFooter: true},
  },
  {
    path: '/subscription-confirm',
    component: subscriptionConfirmPage,
    name: 'subscription-confirm',
    meta: {showMcrFooter: true},
  },
  {
    path: '/subscription-success',
    component: subscriptionSuccessfulPage,
    name: 'subscription-success',
    meta: {showMcrFooter: true},
  },
  {
    path: '/payment/checkout-success',
    component: PaymentCheckoutSuccessPage,
    name: 'payment-checkout-success',
  },
  {path: '*', name: 'not-found', component: PageNotFound},
];

// used in webpack config
module.exports = {default: routes};
